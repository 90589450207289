*,
html,
body,
p {
    margin: 0;
    padding: 0;
}
a,
a:active,
a:focus,
a:hover {
    color: inherit;
}

html,
body {
    height: 100%;
}
#root {
    height: 100%;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content {
    flex: 1 0 auto;
}
.footer {
    flex: 0 0 auto;
}
.row-hover {
    cursor: pointer;
}
#root .row-hover.row-selected,
#root .row-hover.row-selected:hover {
    background: #3f8aca;
    color: #fff;
}
.people-menu-item {
    border: 1px solid #eaeaea;
    width: 100px;
    height: 100px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 1px;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 23px 2px;
    line-height: 17px;
}
.people-menu-item i {
    margin-bottom: 4px;
}

.active .people-menu-item {
    border-color: #40a9ff;
    border-width: 3px;
    padding: 21px 0px;
}
#root .ag-cell:not(.ag-cell-focus) {
    border: 1px solid #f5f5f5;
}
#root .compact-table th,
#root .compact-table td {
    padding: 1px;
    font-size: 12px;
}
#root .compact-table th {
    text-align: center;
}
#root .ag-header-cell-label,
#root .ag-header-group-cell-label {
    justify-content: center;
}
/* reduce ag-grid default header line-height and padding */
#root .ag-theme-balham .ag-header-cell {
    line-height: normal;
    padding: 8px;
}

/* make header separator height proportional to header height */
#root .ag-theme-balham .ag-header-cell:after,
#root .ag-theme-balham .ag-header-group-cell:after {
    height: 50%;
}

/* set icons height to their real absolute value to ensure proper vertical alignment */
#root .ag-theme-balham .ag-header-cell-menu-button .ag-icon-menu,
#root .ag-theme-balham .ag-header-cell-label .ag-header-icon {
    height: 16px;
}

/* make header text wrap, without breaking words and without ellipsis */
#root .ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    height: auto;
    overflow: visible;
    overflow-wrap: normal;
    text-overflow: clip;
    white-space: normal;
}
.right-down, .right-up {
    position: absolute;
    right: 0;
    width: 120px;
}
.left-down, .left-up {
    position: absolute;
    left: 0;
    width: 120px;
}
.events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }
  .ant-fullcalendar-header .ant-radio-group {
    display: none !important
}

@media (min-width: 768px) {
    .che-form {
        max-width: 1200px;
        margin-left: 1%;
        margin-right: 1%;
    }
}
@media (min-width: 992px) {
    .che-form {
        max-width: 1200px;
        margin-left: 15%;
        margin-right: 15%;
    }
}
@media (min-width: 1200px) {
    .che-form {
        max-width: 1200px;
        margin-left: 15%;
        margin-right: 15%;
    }
}

.avatar-uploader > .ant-upload {
    width: 30%;
    height: 30%;
  }
.call {
    color:#ff0000;
    margin-top: 20px;
}  
.callinfo {
    margin-bottom: 20px;
}
.chat-form {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: left;
}
.membersForm {
    margin-bottom: 40px;
    margin-top: 40px;
}

.messageBodyscroll {
    height: 400px;
    overflow-y: scroll;
}

.ant-carousel .slick-slide {
    overflow: hidden;
    height: 160px;
    background: #364d79;
}
  
.ant-carousel .slick-slide div {
    color: #fff;
}

.login-logo-svfu {
    background: url('./images/logo_svfu.png');
    width: 100px;
    height: 86px;
}

.watermarked {
    position: relative;
}

.watermarked:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50px;
    left: 0px;
    background-image: url("./images/watermark.png");
    background-size: 800px 800px;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    opacity: 0.1;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
}
.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}